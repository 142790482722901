import React, {useState, useEffect, useCallback} from 'react';
import {useBlocker, useNavigate, useParams} from 'react-router-dom';
import {useEventQuestions} from '../data/firebaseHooks';
import {
    Box,
    Typography,
    TextField,
    Button,
    CircularProgress,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormLabel,
    RadioGroup,
    Radio
} from '@mui/material';
import {useSelector} from 'react-redux';
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import ButtonGroup from "@mui/material/ButtonGroup";
import TagDropContainer from "../component/Categorization/TagDropContainer";
import {GLOBALS} from "../GLOBALS";
import {Helmet} from "react-helmet";
import FlowFix from "../component/FlowFix/FlowFix";
import CodeQuiz from "../component/Code/CodeQuiz";
import RuleBar from "../component/RuleBar";

const Quiz = () => {
    const user = useSelector((state) => state.user);
    const {id} = useParams();
    const navigate = useNavigate();

    const [eventData, isLoading, error, submitAnswer, userAnswers] = useEventQuestions(id);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answer, setAnswer] = useState(null);
    const [hasAttempted, setHasAttempted] = useState(false);
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer((prev) => prev + 1);
        }, 1000);

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    useEffect(() => {
        if (user?.email && userAnswers) {
            const attempted = Object.keys(userAnswers).some(key => key.startsWith(`${id}:`));
            setHasAttempted(attempted);
        }
    }, [user?.email, userAnswers, id]);

    const handleSubmit = async () => {
        if (!eventData || !eventData.questions[currentQuestionIndex]) return;

        const question = eventData.questions[currentQuestionIndex];
        const qid = question.sid;
        try {
            const response = await submitAnswer(qid, answer, timer);
            if (response) {
                setCurrentQuestionIndex((prev) => prev + 1);
                setAnswer(null);
                setTimer(0);
            }
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    };

    if (!eventData && isLoading) return <Box
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress/></Box>;
    if (eventData && eventData.done) {
        setTimeout(() => {
            navigate('/');
        }, 3000);
        return <Typography variant="h4" sx={{color: GLOBALS.primaryColor, mt: 10}}>Event has ended. Redirecting to
            events page
            in 5 seconds...</Typography>;
    }
    if (eventData && !eventData.accept) {
        setTimeout(() => {
            navigate('/');
        }, 3000);
        return <Typography variant="h4" sx={{color: GLOBALS.primaryColor, mt: 10}}>Event has not started yet.
            Redirecting to
            events page in 5 seconds...</Typography>;
    }
    if (!isLoading && !eventData) {
        return <Typography variant="h4" sx={{color: GLOBALS.primaryColor, mt: 10}}>No Event Data</Typography>;
    }

    if (hasAttempted) {
        return <Typography variant="h3">You have attempted the event, Will release the answers shortly.</Typography>;
    }

    const question = eventData?.questions[currentQuestionIndex];
    if (!question) return <Typography variant="h3">Thank you for playing</Typography>;

    return (
        <Box sx={{padding: 2, maxWidth: "1000px", width: "80vw"}}>
            {error && <Alert severity="error">{error}</Alert>}
            {isLoading && <CircularProgress/>}
            <Question
                question={question}
                answer={answer}
                onAnswerChange={setAnswer}
                onSubmit={handleSubmit}
            />
        </Box>
    );
};

const Question = ({question, onAnswerChange, onSubmit, answer}) => {
    return (
        <Box>
            <RuleBar type={question.type}/>
            <MarkdownRenderer content={question.sid + "." + question.question}/>
            <InputFieldsDisplay question={question} handleChange={onAnswerChange} handleSubmit={onSubmit}
                                answer={answer}/>
        </Box>
    );
};

const InputFieldsDisplay = ({question, answer, handleChange, handleSubmit}) => {
    const [teasureHuntError, setTeasureHuntError] = useState(null);
    if (!question) return null;
    const {type, options} = question;

    const handleOptionChange = (event, value) => {
        const checked = event.target.checked;
        handleChange(prev => {
            const newAnswer = new Set(prev);
            if (checked) {
                newAnswer.add(value);
            } else {
                newAnswer.delete(value);
            }
            return Array.from(newAnswer);
        });
    };

    const normalizeString = str => str ? str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().trim() : null;

    // const handleSubmitTeasure = () => {
    //     const normalizedAnswer = normalizeString(answer);
    //     if (question.answers.some(ans => normalizeString(ans) === normalizedAnswer)) {
    //         handleSubmit();
    //     } else {
    //         setTeasureHuntError("Incorrect Answer");
    //     }
    // }

    switch (type) {
        case 'STORY':
            return (
                <>
                <br/>
                <ButtonGroup variant="contained" aria-label="outlined primary button group" fullWidth>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>Enter the Treasure Hunt</Button>
                </ButtonGroup>
                </>
            )
        case 'PIXEL':
            return (
                <>
                    {/*{answer && answer.toString()}*/}
                    <br/>
                    <FormControl fullWidth margin="normal" error={null}>
                        <FormLabel>Enter the terminology that relates to the image</FormLabel>
                        <TextField
                            label="Word relates to the image"
                            variant="outlined"
                            value={answer || ''}
                            onChange={(event) => handleChange(event.target.value)}
                        />
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <br/>
                    <ButtonGroup variant="contained" aria-label="outlined primary button group" fullWidth>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                    </ButtonGroup>
                </>
            );
        case 'MCQ':
            return (
                <>
                    {/*{answer && answer.toString()}*/}
                    <br/>
                    <FormGroup>
                        <FormLabel>Select all that apply</FormLabel>
                        {options.map((option) => (
                            <FormControlLabel control={<Checkbox
                                checked={Array.isArray(answer) && answer.includes(option)}
                                onChange={(e) => handleOptionChange(e, option)}/>} label={option}/>
                        ))}
                    </FormGroup>
                    <br/>
                    <ButtonGroup variant="contained" aria-label="outlined primary button group" fullWidth>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                    </ButtonGroup>
                </>
            );
        case 'ODD-OUT':
            return (
                <>
                    {/*{answer && answer.toString()}*/}
                    <br/>
                    <FormControl>
                        <FormLabel>Choose the correct option</FormLabel>
                        <RadioGroup
                            aria-labelledby="odd-assertion-out-group-label"
                            name="odd-assertion-out-group"
                            value={answer || ''}
                            onChange={(event) => handleChange(event.target.value)}
                        >
                            {options.map((option) => (
                                <FormControlLabel value={option} control={<Radio/>} label={option}/>
                            ))}
                        </RadioGroup>
                    </FormControl>
                    <br/>
                    <ButtonGroup variant="contained" aria-label="outlined primary button group" fullWidth>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                    </ButtonGroup>
                </>
            );
        case 'TREASURE':
            return (
                <>
                    {/*{answer && answer.toString()}*/}
                    <br/>
                    <FormControl fullWidth margin="normal" error={teasureHuntError}>
                        <FormLabel>
                            Enter the answer to move to next question
                        </FormLabel>
                        <TextField
                            label="Enter your answer"
                            variant="outlined"
                            value={answer || ''}
                            onChange={(e) => handleChange(e.target.value)}
                        />
                        <FormHelperText>{teasureHuntError}</FormHelperText>
                    </FormControl>
                    <br/>
                    <ButtonGroup variant="contained" aria-label="outlined primary button group" fullWidth>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                    </ButtonGroup>
                </>
            );
        case 'FLOW-FIX':
            return (
                <>
                    <Typography variant={'h6'}>
                        Reorder the flow
                    </Typography>
                    <FlowFix questionData={question} handleChange={handleChange} answer={answer}/>
                    <br/>
                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                        <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                    </ButtonGroup>
                </>
            )
        case "CATEGORIZATION":
            return (
                <>
                    <Typography variant={'h6'}>
                        Categorize the below options
                    </Typography>
                    <TagDropContainer
                        data={question}
                        answer={answer}
                        handleChange={handleChange}
                    />
                    <br/>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </>
            );
        case "CODE":
            return (<>
                <br/>
                <CodeQuiz question={question} answer={answer} handleChange={handleChange}/>
                <br/>
                <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
            </>)
        default:
            return <>
                {/*{answer && answer.toString()}*/}
                <br/>
                <Typography>Question type not supported</Typography>
                <br/>
                <Button variant="contained" color="primary" onClick={handleSubmit}>Skip</Button>
            </>;
    }
};

export const MarkdownRenderer = ({content}) => {
    let formattedText = content
        .replace(/\\n/g, '<br/>')
        .replace(/\{\{(IMAGE|VIDEO|AUDIO)\[(.*?)\]\}\}/g, (match, type, filename) => {
            let mediaElement = '';
            switch (type) {
                case 'IMAGE':
                    mediaElement = `<div style="text-align: center;"><img src="${process.env.PUBLIC_URL}/${filename}" alt="${filename}" style="height: 300px;" /></div>`;
                    break;
                case 'VIDEO':
                    mediaElement = `<div style="text-align: center;"><video controls style="height: 300px;"><source src="${process.env.PUBLIC_URL}/${filename}" type="video/mp4">Your browser does not support the video tag.</video></div>`;
                    break;
                case 'AUDIO':
                    mediaElement = `<div style="text-align: center;"><audio controls><source src="${process.env.PUBLIC_URL}/${filename}" type="audio/mpeg">Your browser does not support the audio element.</audio></div>`;
                    break;
                default:
                    mediaElement = match;
            }
            return `<br/>${mediaElement}<br/>`;
        });

    formattedText = formattedText
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold
        .replace(/__(.*?)__/g, '<strong>$1</strong>') // Bold (alternative syntax)
        .replace(/\*(.*?)\*/g, '<em>$1</em>') // Italic
        .replace(/_(.*?)_/g, '<em>$1</em>') // Italic (alternative syntax)
        .replace(/<u>(.*?)<\/u>/g, '<u>$1</u>'); // Underline

    return (
        <div dangerouslySetInnerHTML={{__html: formattedText}}
             style={{textAlign: "left", fontSize: "120%", fontWeight: "bold"}}/>
    );
};

export default Quiz;
