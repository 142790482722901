import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useLeaderboard } from '../data/firebaseHooks';
import {Alert, Box, CircularProgress, Typography} from '@mui/material';
import {Helmet} from "react-helmet";

const LeaderboardPage = () => {
    const { row, column, loading, error } = useLeaderboard();

    if (loading) {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        );
    }


    return (
        <Box sx={{ height: "70vh", width: '80vw' }}>
            <Helmet>
                <title>Leaderboard | Tech Excellence - NexTuple Inc</title>
            </Helmet>
            <Typography variant="h4" gutterBottom>
                Leaderboard
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <DataGrid
                rows={row}
                columns={column}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
            />
        </Box>
    );
};

export default LeaderboardPage;
