import React, {useState} from 'react';
import {Box, Typography, Drawer} from '@mui/material';
import {GLOBALS} from "../GLOBALS";

const RuleBar = ({type}) => {
    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };
    let content, title;
    switch (type) {
        case 'PIXEL':
            title = "Pixel Puzzle";
            content = <>
                <Typography variant="subtitle1">Your task is answer the question using the visual hints
                    provided.</Typography>
                <Typography variant="subtitle1">Each question will have an answer box where you can enter your
                    response.</Typography>
                <Typography variant="subtitle1">Answers are not case-sensitive, but correct spelling is
                    required.</Typography>
                <Typography variant="subtitle1">Some questions may have multiple correct answers. Entering any one
                    correct answer will earn you a point.</Typography>
                <Typography variant="subtitle1">The fastest correct answer will receive the highest score.</Typography>
                <Typography variant="subtitle1">Your final score will be adjusted based on the number of participants
                    and the highest score achieved during the event.</Typography>
            </>
            break;
        case 'CODE':
            title = "Tag The Code";
            content = <>
                <Typography variant="subtitle1">Give a piece of code, tag any line that displays incorrect coding
                    practices.</Typography>
                <Typography variant="subtitle1">You will receive the maximum score by placing all entries in the
                    appropriate categories.</Typography>
                <Typography variant="subtitle1">Incorrect answers have no negative scoring.</Typography>
            </>
            break;
        case 'MCQ':
            title = "Multiple Choice";
            content = <>
                <Typography variant="subtitle1">A question may have more than one answer. Select all options that
                    apply.</Typography>
                <Typography variant="subtitle1">For each correct answer, you receive an equal portion of the total score
                    awarded to that question.</Typography>
                <Typography variant="subtitle1">For each incorrect answer, you are deducted half of the score awarded
                    for the correct answer.</Typography>
            </>
            break;
        case 'ODD-OUT':
            title = "Option";
            content = <>
                <Typography variant="subtitle1">Each question will have only one answer. Select the odd statement out of
                    the given options.</Typography>
                <Typography variant="subtitle1">The fastest correct answer will receive the highest score.</Typography>
            </>
            break;
        case 'FLOW-FIX':
            title = "Flow Fix";
            content = <>
                <Typography variant="subtitle1">Each question has a series of steps. Drag and drop the options to
                    arrange them in the correct order.</Typography>
                <Typography variant="subtitle1">Only one correct flow exists for each question.</Typography>
                <Typography variant="subtitle1">The fastest correct answer will receive the highest score.</Typography>
            </>
            break;
        case 'CATEGORIZATION':
            title = "Categorization the Options";
            content = <>
                <Typography variant="subtitle1">Drag and drop each option into the correct category.</Typography>
                <Typography variant="subtitle1">Each option belongs to only one category.</Typography>
                <Typography variant="subtitle1">You will receive the maximum score by placing all entries in the
                    appropriate categories.</Typography>
                <Typography variant="subtitle1">Incorrect answers have no negative scoring.</Typography>
            </>
            break;
        case 'TREASURE':
            title = "Treasure Hunt";
            content = <>
                <Typography variant="subtitle1">Each question has only one correct answer.</Typography>
                <Typography variant="subtitle1">Clues cannot be skipped or revisited.</Typography>
                <Typography variant="subtitle1">The fastest correct answer will receive the highest score.</Typography>
                <Typography variant="subtitle1">Your final score will be adjusted based on the number of participants
                    and the maximum score of the event.</Typography>
            </>
            break;
    }
    title= "Treasure Hunt";
    content= <>
        <Typography variant="subtitle1">Questions cannot be revisited.</Typography>
        <Typography variant="subtitle1">The fastest correct answer will receive the highest score.</Typography>
        <Typography variant="subtitle1">Your final score will be adjusted based on the number of participants and the maximum score of the event.</Typography>
        <Typography variant="subtitle1">No negative scoring.</Typography>
        </>
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box
                sx={{
                    writingMode: 'vertical-lr',
                    textOrientation: "upright",
                    padding: '10px',
                    cursor: 'pointer',
                    position: 'fixed',
                    top: '50%',
                    left: 0,
                    height: "100vh",
                    transform: 'translateY(-50%)',
                    zIndex: 10,
                    background: GLOBALS.primaryColor
                }}
                onClick={toggleDrawer(true)}
            >
                <Typography variant="h6" noWrap color="#fff">
                    &#x27A4;&#x27A4; RULES & RANKING &#x27A4;&#x27A4;
                </Typography>
            </Box>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                <Box sx={{width: "60vw", m: 3}}>
                    <Typography variant="h5" sx={{margin: '10px'}} color="primary">
                        Rules for {title}
                    </Typography>
                    {content}
                </Box>
            </Drawer>
        </Box>
    );
};

export default RuleBar;
